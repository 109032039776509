import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { colors, devices, fontWeights } from '../styles/theme';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  BuildLink,
  Intent,
  LearnMoreLink,
  OptionInfo,
  PaddedGrid,
  StartingOption,
  StartingOptions,
  TextBlock,
} from '../styles/shared';
import { communitiesPageContent, homePageContent } from '../utils/dataConfig';
import YourCityForm from '../components/forms/YourCityForm';
import RadioToggle from '../components/RadioToggle';
import Modal from '../components/Modal';
import Map from '../components/modals/Map';
import ImageCarousel from '../components/ImageCarousel';
import { storeReferralData } from '../utils/helpers';
import SEO from '../components/SEO';

const heroContent = [
  {
    title: 'Neu Mountain View',
    description:
      'Near the highest point in Travis county Neu Mountain View offers residents a million dollar view without having to be a millionaire.',
    community: 'mountain',
    // tabName: 'mountain',
  },
  {
    title: 'Neu Lake Travis',
    description:
      'Waterfront living at its best. Leave your car behind while you live, work and play on Neu’s private beachfront.',
    community: 'lake-travis',
    // tabName: 'lake',
  },
  {
    title: 'Neu Forest',
    description:
      'Spectacular setting nestled in the woods. Create your own private retreat surrounded by nature. Only twenty minutes from Tesla.',
    community: 'bastrop',
    // tabName: 'forest',
  },
];

const CommunitiesPage = ({ data, location }) => {
  // store referral params
  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      storeReferralData(params);
    }
  }, []);

  const images = data.allFile.nodes;
  const heroImages = heroContent
    .map(hero =>
      data.heroes.nodes.find(
        image => image.name === `home-community-${hero.community}`
      )
    )
    .map(image => ({ image }));
  const [mapData, setMapData] = useState(null);
  const handleMapClick = data => {
    setMapData(data);
  };
  const handleClose = () => setMapData(null);

  // intent toggle controls
  const [intents, setIntents] = useState({});
  const changeIntent = (community, lot, intent) =>
    setIntents(prev => ({
      ...prev,
      [community]: { ...prev[community], [lot]: intent },
    }));

  const decorImages = data.decor.nodes
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(image => image.childImageSharp.gatsbyImageData);

  return (
    <>
      <SEO title='Communities' />
      <Layout>
        <CarouselWithInfo images={heroImages} hideNav autoNext={6000}>
          {imageIndex => {
            const info = heroContent[imageIndex];
            return (
              <InfoContainer>
                <h1>{info.title}</h1>
                <p>{info.description}</p>
                <div className='buttons'>
                  {/*<Link className='gallery' to={'/gallery/?t=' + info.tabName}>*/}
                  {/*  Gallery*/}
                  {/*</Link>*/}
                  <a className='start-building' href={`#${info.community}`}>
                    Start Building
                  </a>
                </div>
              </InfoContainer>
            );
          }}
        </CarouselWithInfo>
        <StyledPaddedGrid>
          <TextBlock>
            <h1>{homePageContent.section1.heading}</h1>
            <p>{homePageContent.section1.paragraph}</p>
          </TextBlock>
          <Section1Decor
            image={decorImages[0]}
            style={{ position: 'absolute' }}
            alt='background decor art'
          />
        </StyledPaddedGrid>
        <Container>
          <CommunityOptionsContainer>
            {communitiesPageContent.community.list.map(community => (
              <Community key={community}>
                <span className='anchor-target' id={community} />
                <Heading>
                  <h1>{communitiesPageContent.community[community].title}</h1>
                  <div className='subheading'>
                    <p>
                      {communitiesPageContent.community[community].subtitle}
                    </p>
                    {/*<MapButton*/}
                    {/*  onClick={() =>*/}
                    {/*    handleMapClick(*/}
                    {/*      communitiesPageContent.community[community].map*/}
                    {/*    )*/}
                    {/*  }*/}
                    {/*>*/}
                    {/*  map*/}
                    {/*</MapButton>*/}
                  </div>
                </Heading>
                <StartingOptions>
                  {communitiesPageContent.community[community].lots.list.map(
                    lot => {
                      const optionData =
                        communitiesPageContent.community[community].lots[lot];
                      return (
                        <StartingOption key={lot}>
                          {images && (
                            <GatsbyImage
                              image={
                                images.find(
                                  image =>
                                    image.name ===
                                    `communities-${community}-${lot}`
                                ).childImageSharp.gatsbyImageData
                              }
                              alt={`${community} ${lot} lot`}
                            />
                          )}
                          <OptionInfo>
                            <h2>{optionData.title}</h2>
                            {/*<UnitsLeft>*/}
                            {/*  {optionData.unitsLeft} lots left*/}
                            {/*</UnitsLeft>*/}
                            <div>
                              <p>{optionData.description}</p>
                              <ul>
                                {optionData.features.map(feature => (
                                  <li key={feature}>&bull; {feature}</li>
                                ))}
                              </ul>
                            </div>
                            <Intent>
                              <h3>Start building as:</h3>
                              <RadioToggle
                                current={
                                  (intents[community] &&
                                    intents[community][lot]) ||
                                  'own'
                                }
                                left={{ value: 'own', title: 'owner' }}
                                right={{ value: 'rent', title: 'renter' }}
                                handleClick={intent =>
                                  changeIntent(community, lot, intent)
                                }
                              />
                              <LearnMoreLink to='/own-vs-rent/'>
                                learn more
                              </LearnMoreLink>
                            </Intent>
                            <BuildLink
                              to={`/builder/?src=communities&c=${community}&l=${lot}&i=${
                                (intents[community] &&
                                  intents[community][lot]) ||
                                'own'
                              }`}
                            >
                              Build
                            </BuildLink>
                          </OptionInfo>
                        </StartingOption>
                      );
                    }
                  )}
                </StartingOptions>
              </Community>
            ))}
          </CommunityOptionsContainer>
          <YourCityForm />
        </Container>
        {mapData && (
          <Modal
            anchorStyle={{ width: '100%', height: '100%' }}
            handleClose={handleClose}
          >
            <Map data={mapData} handleClose={handleClose} />
          </Modal>
        )}
      </Layout>
    </>
  );
};
const Container = styled.section`
  padding: var(--body-padding-y) var(--body-padding-x);
`;
const CommunityOptionsContainer = styled.div`
  display: grid;
  row-gap: 2.5rem;
`;
const Community = styled.article`
  display: grid;
  row-gap: 2.25rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid ${colors.black};
  // offset anchor target to compensate for site header
  position: relative;
  span.anchor-target {
    position: absolute;
    top: -6.5rem;
  }
`;
const Heading = styled.div`
  display: grid;

  h1 {
    grid-column: span 2;
    font-size: 2.125rem;
    line-height: 3rem;
    font-weight: ${fontWeights.bold};
  }

  .subheading {
    display: grid;
    grid-auto-flow: column;
    column-gap: 1.25rem;
    justify-content: start;

    > * {
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: ${fontWeights.regular};
    }
  }
`;
const MapButton = styled.button`
  color: ${colors.blue};
  text-decoration: underline;
`;

const UnitsLeft = styled.div`
  border-radius: 0.375rem;
  font-size: 1rem;
  line-height: 2rem;
  padding: 0.25rem 0.5rem 0; // top padding workaround vertically centering text
  width: 8.5rem;
  min-width: max-content;
  background-color: ${colors.lighter};
`;

const StyledPaddedGrid = styled(PaddedGrid)`
  padding-top: 7rem;
  @media (${devices.s}) {
    padding-top: 4rem;
  }
`;
const Section1Decor = styled(GatsbyImage)`
  //top: calc(var(--image-text-row-gap) * -1);
  top: 0.5rem;
  right: 0;
  width: 49%;
  z-index: -1;
`;
const CarouselWithInfo = styled(ImageCarousel)`
  height: 42rem;
  max-height: 70vh;
`;
const InfoContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 6% 4%;
  z-index: 1;
  color: ${colors.white};
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
  display: grid;
  row-gap: 1.5rem;
  h1 {
    font-weight: ${fontWeights.medium};
    font-size: 4rem;
    line-height: 4.5rem;
  }
  p {
    max-width: 34rem;
    font-size: 1rem;
    line-height: 2rem;
  }
  .buttons {
    display: grid;
    grid-auto-flow: column;
    column-gap: 1.5rem;
    justify-content: start;
  }
  a {
    height: 3.5rem;
    width: 10rem;
    border-radius: 0.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: ${fontWeights.medium};
    &.start-building {
      background-color: ${colors.blue};
      color: ${colors.white};
    }
    &.gallery {
      border: 1px solid ${colors.gray};
    }

    :hover {
      filter: brightness(1.5);
    }
  }
  @media (${devices.s}) {
    row-gap: 1rem;
    h1 {
      font-size: 3.4rem;
      line-height: 5.1rem;
    }
  }
  @media (${devices.xs}) {
    row-gap: 0.75rem;
    h1 {
      font-size: 2.6rem;
      line-height: 3.5rem;
    }
  }
`;
export default CommunitiesPage;

export const pageQuery = graphql`
  query getCommunitiesImages {
    allFile(filter: { name: { regex: "/^communities/" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 70
            height: 300
            aspectRatio: 1.8
            placeholder: BLURRED
          )
        }
      }
    }
    heroes: allFile(filter: { name: { regex: "/^home-community/" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(quality: 70, width: 1440, placeholder: BLURRED)
        }
      }
    }
    decor: allFile(filter: { name: { regex: "/^home-decor/" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(quality: 70, placeholder: NONE, width: 800)
        }
      }
    }
  }
`;
