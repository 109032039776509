import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, devices, fontWeights } from '../../styles/theme';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { BuildLink, Input, InputGroup, SolidButton } from '../../styles/shared';
import { useSelector } from 'react-redux';
import { getSubmittedForms, getVisitorInfo } from '../../store/app';
import useForm from '../../utils/useForm';
import { validateForm } from '../../utils/helpers';
import { submitCity } from '../../utils/api';
import { newCityFormContent } from '../../utils/dataConfig';

const formName = 'yourCity';
const YourCityForm = () => {
  const data = useStaticQuery(graphql`
    query getYourCityImage {
      file(name: { eq: "your-city" }) {
        childImageSharp {
          gatsbyImageData(quality: 70, width: 480, placeholder: BLURRED)
        }
      }
    }
  `);
  const yourCityImage = data.file.childImageSharp.gatsbyImageData;

  const [isSubmitting, setSubmitting] = useState(false);
  const visitorInfo = useSelector(getVisitorInfo);
  const { firstName, email, city, state } = visitorInfo;
  const formSubmitted = useSelector(getSubmittedForms).some(
    f => f === formName
  );

  const { values, handleChange, handleBlur, handleSubmit, errors } = useForm(
    formName,
    { firstName, email, city, state },
    validateForm,
    isSubmitting,
    submitting => setSubmitting(submitting),
    submitCity
  );
  return (
    <Container>
      <Illustration>
        <GatsbyImage
          alt='your city illustration'
          image={yourCityImage}
          style={{ height: '100%' }}
        />
      </Illustration>
      {formSubmitted ? (
        <ThankYou>{newCityFormContent.thankYouMessage}</ThankYou>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Heading>
            <h2>{newCityFormContent.heading}</h2>
            <p>{newCityFormContent.paragraph}</p>
          </Heading>
          <Inputs>
            <InputGroup>
              <p>First name</p>
              <Input
                type='text'
                name='firstName'
                onChange={handleChange}
                value={values.firstName}
                onBlur={handleBlur}
                required
                className={!!errors.firstName ? 'error' : ''}
              />
              <p className='error'>{errors.firstName}</p>
            </InputGroup>
            <InputGroup>
              <p>Email</p>
              <Input
                type='email'
                name='email'
                onChange={handleChange}
                value={values.email}
                onBlur={handleBlur}
                required
                className={!!errors.email ? 'error' : ''}
              />
              <p className='error'>{errors.email}</p>
            </InputGroup>
            <InputGroup>
              <p>Your city</p>
              <Input
                type='text'
                name='city'
                onChange={handleChange}
                value={values.city}
                onBlur={handleBlur}
                required
                className={!!errors.city ? 'error' : ''}
              />
              <p className='error'>{errors.city}</p>
            </InputGroup>
            <InputGroup>
              <p>Your state</p>
              <Input
                type='text'
                name='state'
                onChange={handleChange}
                value={values.state}
                onBlur={handleBlur}
                required
                className={!!errors.state ? 'error' : ''}
              />
              <p className='error'>{errors.state}</p>
            </InputGroup>
          </Inputs>
          <SolidButton
            type='submit'
            disabled={isSubmitting || Object.keys(errors).length !== 0}
          >
            Submit
          </SolidButton>
          {errors.submission && <p className='error'>{errors.submission}</p>}
        </Form>
      )}
    </Container>
  );
};
const Container = styled.article`
  max-width: 47rem;
  margin-top: 4.25rem;
  position: relative;
  display: grid;
`;
const Illustration = styled.div`
  grid-area: 1 / 1;
  justify-self: end;
  align-self: stretch;
  z-index: 0;
  margin-right: -2rem;
  opacity: 0.6;
  // @media (${devices.s}) {
  // }
  @media (${devices.xs}) {
    height: 10rem;
    opacity: 0.3;
  }
`;
const Form = styled.form`
  padding: 2.25rem 20rem 3.25rem 2.5rem;
  grid-area: 1 / 1;
  z-index: 1;
  & p.error {
    color: ${colors.red};
    font-size: 0.875rem;
    line-height: 1.3125rem;
    justify-self: end;
  }
  height: auto;
  @media (${devices.xs}) {
    padding: 5rem 1.5rem 1.5rem;
  }
`;
const Heading = styled.div`
  display: grid;
  row-gap: 0.75rem;

  h2 {
    font-weight: ${fontWeights.bold};
    font-size: 2.125rem;
    line-height: 3rem;
  }

  p {
    font-size: 1rem;
    line-height: 2rem;
  }
`;
const Inputs = styled.div`
  margin: 1.5rem 0;
  display: grid;
  max-width: 16.5rem;
  @media (${devices.xs}) {
    max-width: unset;
  }
`;
const SubmitButton = styled(SolidButton)`
  background-color: ${colors.blue};
  color: ${colors.white};
  border: none;
`;
const ThankYou = styled.div`
  grid-area: 1 / 1;
  justify-self: start;
  align-self: center;
  width: 20rem;
  padding-left: 3.25rem;
  z-index: 1;
  font-size: 1.25rem;
  line-height: 2.25rem;
  font-weight: ${fontWeights.medium};
  @media (${devices.xs}) {
    padding-left: unset;
    width: unset;
    justify-self: center;
    text-align: center;
  }
`;
export default YourCityForm;
